import React, { useEffect, useRef, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Header from "./Components/header";
import OrderDetails from "./Components/orderdetails";
import ShipmentSummary from "./Components/shipmentSummary";
import Trackingandnotes from "./Components/trackingandnotes";
import Fulfilment from "./Components/fulfilment";
import PrintableOrderDetails from "./PrintableOrderDetails";

import html2pdf from "html2pdf.js";
import { fetchSingleOrder } from "../../../../../services/orders.services";
import CarrierInnerTableLoader from "../../../../../Components/CarrierInnerTableLoader/CarrierInnerTableLoader";

export default function DetailsModal({
  open,
  setOpen,
  setSnack,
  setOrders,
  editable = false,
}) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [additionalCosts, setadditionalCosts] = useState([]);
  const [cost, setcost] = React.useState(null);
  const [value, setValue] = React.useState(null);
  const [additonalPrice, setadditonalPrice] = React.useState(0);
  const [AdditionalCostTotal, setAdditionalCostTotal] = useState(0);
  const [deletedids, setdeletedids] = useState([]);
  const [Packinglist, setPackinglist] = useState(false);
  const [orderStatus, setorderStatus] = useState([]);

  const invoiceRef = useRef(null);

  const [selectedStatus, setselectedStatus] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setData({});
    setadditionalCosts([]);
    setcost(null);
    setValue(null);
    setadditonalPrice(0);
    setAdditionalCostTotal(0);
    setdeletedids([]);
    setLoading(true);
  };
  const getOrder = async (id) => {
    setLoading(true);
    let response = await fetchSingleOrder(id);
    if (!response.error) {
      setData(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open[0]) {
      getOrder(open[0]);
    }
    setPackinglist(open[1]);
  }, [open]);

  const downloadPdf = async (id) => {
    const input = invoiceRef.current;
    const firstName = (
      data?.order_shipping_address_details?.first_name || ""
    ).trim();
    const lastName = (
      data?.order_shipping_address_details?.last_name || ""
    ).trim();
    const orderId = (id || "order").trim();

    const name = `${orderId}${firstName ? `-${firstName}` : ""}${
      lastName ? `-${lastName}` : ""
    }.pdf`;
    const options = {
      filename: `${name}`,
      format: "A4",
      jsPDF: { unit: "px", format: [835, 1123.2], orientation: "p" },
      image: { type: "jpeg", quality: 0.98 },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      html2canvas: { scale: 2, useCORS: true },
    };

    await html2pdf().set(options).from(input).save();
  };

  return (
    <>
      <div
        style={{
          zIndex: "-1000",
          position: "absolute",
          visibility: "hidden",
          left: "-1000",
        }}
        className="PrintableOrderDetails_modal"
      >
        <PrintableOrderDetails
          invoiceRef={invoiceRef}
          editable={false}
          data={data}
          AdditionalCostTotal={AdditionalCostTotal}
          setadditionalCosts={setadditionalCosts}
          additionalCosts={additionalCosts}
          deletedids={deletedids}
          setdeletedids={setdeletedids}
          setSnack={setSnack}
          setData={setData}
          setOrders={setOrders}
          cost={cost}
          setcost={setcost}
          value={value}
          setValue={setValue}
          open={open}
          additonalPrice={additonalPrice}
          setadditonalPrice={setadditonalPrice}
          setAdditionalCostTotal={setAdditionalCostTotal}
          selectedStatus={selectedStatus}
          setselectedStatus={setselectedStatus}
          Packinglist={Packinglist}
          orderStatus={orderStatus}
          setorderStatus={setorderStatus}
        />
      </div>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <div
            className="proddetails__desc_modal detail__modal_order_view"
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              position: "absolute",
              backgroundColor: "white",
              padding: "20px",
              maxHeight: "80%", // Set a maximum height for the modal content
              overflowY: "auto", // Enable vertical scrolling
            }}
          >
            {loading && (
              <div
                style={{
                  width: "100%",
                  height: "250px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CarrierInnerTableLoader />
              </div>
            )}
            {loading === false && (
              <>
                <Header
                  orderId={data?.order_id}
                  handleClose={handleClose}
                  downloadPdf={downloadPdf}
                />
                <div className="proddetails__desc_modalcontent warehouse_cust_prod">
                  <Row className="mx-auto">
                    <Col md={12} className="mx-auto">
                      <Row>
                        <OrderDetails
                          data={data}
                          AdditionalCostTotal={AdditionalCostTotal}
                          Packinglist={Packinglist}
                          setSnack={setSnack}
                        />
                        <ShipmentSummary
                          data={data}
                          selectedStatus={selectedStatus}
                          orderStatus={orderStatus}
                        />
                        <Trackingandnotes data={data} />
                        {!Packinglist && (
                          <Fulfilment
                            editable={editable}
                            data={data}
                            AdditionalCostTotal={AdditionalCostTotal}
                            setadditionalCosts={setadditionalCosts}
                            additionalCosts={additionalCosts}
                            deletedids={deletedids}
                            setdeletedids={setdeletedids}
                            setSnack={setSnack}
                            setData={setData}
                            setOrders={setOrders}
                            cost={cost}
                            setcost={setcost}
                            value={value}
                            setValue={setValue}
                            open={open}
                            additonalPrice={additonalPrice}
                            setadditonalPrice={setadditonalPrice}
                            setAdditionalCostTotal={setAdditionalCostTotal}
                            selectedStatus={selectedStatus}
                            setselectedStatus={setselectedStatus}
                            orderStatus={orderStatus}
                            setorderStatus={setorderStatus}
                          />
                        )}
                      </Row>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </div>
        </Modal>
      </div>
    </>
  );
}
