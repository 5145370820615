import axios from "../../axiosInstance";

export const quoteService = (stepcheck, setstepcheck, payload) => {
  const carriers = [
    ...(stepcheck["Package"] === true
      ? [
          "canpar",
          "gls",
          "ups",
          "canadaPost",
          "fedex",
          "ics",
          "dhl",
          "samedayExpress",
        ]
      : []),
    ...(stepcheck["Pallet"] === true
      ? ["dayAndRoss", "kindersley", "xpo"]
      : []),
  ];

  let completedRequests = 0;

  carriers.forEach((carrier) => {
    const request = {
      method: "POST",
      url: `get-all-rates/${carrier}`,
      data: payload,
    };

    axios(request)
      .then((result) => {
        try {
          const response = result.data;
          if (response.result) {
            // Update QuoteData with each response
            setstepcheck((prevStepCheck) => {
              const updatedData = [
                ...prevStepCheck.QuoteData,
                ...response.result,
              ].sort((a, b) => a.total - b.total);
              return {
                ...prevStepCheck,
                QuoteData: updatedData,
              };
            });
          }
        } catch (error) {
          console.error(error);
        }
      })
      .catch((error) => {
        console.error(`Error with carrier ${carrier}:`, error);
      })
      .finally(() => {
        completedRequests += 1;
        if (completedRequests === carriers.length) {
          setstepcheck((prevStepCheck) => ({
            ...prevStepCheck,
            Pending: false, // Set Pending to false even if all requests fail
          }));
        }
      });
  });
};

export const specficQuoteService = async (id, payload) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`get-specific-rates/${id}`, payload);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const createShipmentService = async (id, payload) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`create-specific-shipment/${id}`, payload);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
