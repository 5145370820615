import React, { useState, useEffect } from "react";
import "./Shipments.css";

// components imports
import TicketCreateModal from "./Components/TicketCreateModal/TicketCreateModal";
import AssignPickup from "./Components/AssignPickup/AssignPickup";
import InvoiceModal from "../../../../../Components/InvoiceModal/InvoiceModal";
import Confirm from "../../../../../Components/Popups/Confirm";
// apis imports
import {
  fetchShipments,
  cancelShipment,
  fetchShipmentDataById,
} from "../../../../../services/ShipperServices/Shipments/shipments.services";
import { updateUser } from "../../../../../store/userSlice";
import { initialShippmentsTab } from "../../../../../data/permissionsArray";
import Snackbar from "../../../../../Components/SnackBar/SnackBar";
import CircularLoader from "../../../../../Components/CircularLoader/CircularLoader";
import ShipmentsTable from "../../../../../Components/BoltTable/BoltTable";
import StatusIcons from "../../../../../Components/StatusIcons/StatusIcons";
import ActionDropDown from "./Components/TicketCreateModal/ActionDropDown/ActionDropDown";

// library imports
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { MdComment } from "react-icons/md";
import TabsComponent from "../../../../../Components/Common/TabsComponent";

const Shipments = () => {
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [labelDownloading, setLabelDownloading] = useState("");
  const [shipments, setShipments] = useState([]);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [assignPickupModal, setAssignPickupModal] = useState(false);

  const [cancelingShipment, setCancelingShipment] = useState();
  const [tab, setTab] = useState("all");
  const [tabPermissions, setTabPermissions] = useState(initialShippmentsTab);
  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });
  const user = useSelector((state) => state.user);
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (user.role != "Shipper") {
      let validPermissions = initialShippmentsTab.filter((t) =>
        user?.mergedPermissions?.includes(t.name)
      );
      if (validPermissions?.length > 0) {
        setTab(validPermissions[0]?.value);
      } else {
        setTab("nothing");
      }
      setTabPermissions(validPermissions);
    } else {
      setTab("all");
    }
  }, []);
  const [ticketCreateModal, setTicketCreateModal] = useState(false);

  const handleReShip = async (row) => {
    try {
      let data = await fetchShipmentDataById(row?.id);
      if (Object.keys(data?.data?.result).length !== 0) {
        localStorage.setItem("Reship", JSON.stringify(data?.data?.result));
        Navigate("/");
      } else {
        // Handle the case where data is empty
        setSnack((snack) => ({
          ...snack,
          open: true,
          text: "No shipment data available",
          severity: "warning",
        }));
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error("Error fetching shipment data:", error);
      setSnack((snack) => ({
        ...snack,
        open: true,
        text: "Something went wrong",
        severity: "error",
      }));
    }
  };

  const handleTicketOpen = (row) => {
    if (row?.tickets?.length > 0 && row?.tickets[0]?.created_by == user.id)
      Navigate({
        pathname: "/Tickets",
        search: `?ticket_no=${row?.tickets[0]?.ticket_no}`,
      });
    else setTicketCreateModal(row);
  };

  const printLabel = async (row) => {
    setLabelDownloading("label" + row?.id);
    try {
      fetch(row?.single_shipping_labels.file, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      })
        .then((url) => url.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${row.tattension + " " + row.shipment_tracking_id} Label.pdf`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setLabelDownloading("");
        });
    } catch (error) {
      setLabelDownloading("");
    }
  };
  const printDeclaration = async (row) => {
    setLabelDownloading("Declaration" + row?.id);
    // console.log(row.single_shipping_labels.file);
    try {
      fetch(row?.single_shipping_invoice.file, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      })
        .then((url) => url.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${row.tattension + " " + row.shipment_tracking_id} Declaration.pdf`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setLabelDownloading("");
        });
    } catch (error) {
      setLabelDownloading("");
    }
  };
  const handleCancelShipment = async (id) => {
    setCancelingShipment(true);
    try {
      const response = await cancelShipment(id);
      if (!response.error) {
        setShipments((prevShipments) => {
          return {
            ...prevShipments,
            data: prevShipments?.data?.map((shipment) => {
              if (shipment?.id === id) {
                return {
                  ...shipment,
                  shipment_status: response?.data?.result?.shipment_status,
                };
              } else {
                return shipment;
              }
            }),
          };
        });
        setCancelingShipment(false);
        setConfirmCancel(false);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: response?.data?.message,
            severity: "success",
          };
        });
      } else {
        setCancelingShipment(false);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text:
              response?.error?.response?.data?.message ??
              "Something went wrong",
            severity: "error",
          };
        });
      }
    } catch (error) {
      setCancelingShipment(false);
      setSnack({
        ...snack,
        open: true,
        text: error?.message || "Something went wrong!",
        severity: "error",
      });
    }
  };
  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 1,
      label: "Carrier",
      render: (row) => (
        <>
          <img
            src={row?.shipping_company?.shipping_company?.logo_url}
            loading="lazy"
          />
        </>
      ),
      width: "200px",
      fixed: "left",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 2,
      label: "Type",
      render: (row) => <>{row?.shipment_type ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 3,
      label: "Service",
      render: (row) => <>{row?.service_type ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 4,
      label: "Status",
      render: (row) => <StatusIcons status={row?.shipment_status} />,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    // {
    //   id: 50,
    //   label: "Ticket",
    //   render: (row) => (
    //     <div
    //       onClick={() => {
    //         handleTicketOpen(row);
    //       }}
    //     >
    //       <StatusIcons status={row?.tickets[0]?.priority} />
    //     </div>
    //   ),
    //   width: "200px",
    //   disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    // },
    {
      id: 51,
      label: "Ticket",
      render: (row) => (
        <>
          <div className="table_action__wrap">
            <div
              className="table_action__btn"
              onClick={() => {
                handleTicketOpen(row);
              }}
              style={{
                backgroundColor:
                  row?.tickets?.length > 0 &&
                    row?.tickets[0]?.created_by == user.id &&
                    (row?.tickets[0]?.status == "Open" ||
                      row?.tickets[0]?.status == "Dispute" ||
                      row?.tickets[0]?.status == "Disputed")
                    ? "red"
                    : "none",
                color:
                  row?.tickets?.length > 0 &&
                    row?.tickets[0]?.created_by == user.id &&
                    (row?.tickets[0]?.status == "Open" ||
                      row?.tickets[0]?.status == "Dispute" ||
                      row?.tickets[0]?.status == "Disputed")
                    ? "white"
                    : "none",
              }}
            >
              <MdComment size={10} />
              Ticket
            </div>
          </div>
        </>
      ),
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 5,
      label: "Reference ID",
      render: (row) => <>{row?.referance_code ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 6,
      label: "Pickup ID",
      render: (row) => <>{row?.PickupID ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },

    {
      id: 7,
      label: "Bolt Tracking ID",
      render: (row) => <>{row?.tracking_id ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 8,
      label: "Carrier Tracking ID",
      render: (row) => (
        <>{tab === 2 ? row?.id ?? "-" : row?.shipment_tracking_id ?? "-"}</>
      ),
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 9,
      label: "Delivery",
      render: (row) => <>{row?.delivery_date?.slice(0, 10) ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 10,
      label: "Pickup",
      render: (row) => (
        <>
          {" "}
          {row.PickupDate &&
            `${row.PickupDate?.slice(0, 10)}  ${row.readyopentime ? row.readyopentime?.slice(0, 5) : ""
            }${row.readyopentime && row.readyclosetime ? "-" : ""}${row.readyclosetime ? row.readyclosetime?.slice(0, 5) : ""
            }`}
          {!row.PickupDate && (
            <div
              className="putable__arrangepickup_btn"
              onClick={() => setAssignPickupModal(row)}
            >
              Arrange Pickup <FaEdit size={10} style={{ marginLeft: "5px" }} />
            </div>
          )}
        </>
      ),
      width: "250px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 11,
      label: "Est. Delivery Date",
      render: (row) => <>{row?.estimated_delivery_date?.slice(0, 10) ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 12,
      label: "Payment Status",
      render: (row) => <StatusIcons status={row?.payment_status ?? "Paid"} />,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 14,
      label: "Internal Status",
      render: (row) => <StatusIcons status={row?.user_invoice_status} />,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 13,
      label: "Weight",
      render: (row) => <>{row?.total_weight ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    // {
    //   id: 14,
    //   label: "Packages",
    //   render: (row) => <>{row?.shipment_details?.length ?? "-"}</>,
    //   width: "200px",
    //   disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    // },
    {
      id: 15,
      label: "Customer Name",
      render: (row) => <>{row?.tattension ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    // {
    //   id: 11,
    //   label: "Pickup Country",
    //   render: (row) => <>{row?.fcountrycode ?? "-"}</>,
    //   width: "200px",
    // disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    // },
    {
      id: 16,
      label: "Pickup City",
      render: (row) => <>{row?.fcity ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 17,
      label: "Pickup Address",
      render: (row) => <><div className="content__wrapping_boltTable" >{row?.faddress ?? "-"}</div></>,
      width: "300px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 18,
      label: "Pickup Notes",
      render: (row) => <>{row?.finstructions ?? "-"}</>,
      width: "250px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    // {
    //   id: 14,
    //   label: "Delivery Country",
    //   render: (row) => <>{row?.tcountrycode ?? "-"}</>,
    //   width: "200px",
    // disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    // },
    {
      id: 19,
      label: "Delivery City",
      render: (row) => <>{row?.tcity ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 20,
      label: "Delivery Address",
      render: (row) => <><div className="content__wrapping_boltTable" >{row?.taddress ?? "-"}</div></>,
      width: "300px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 21,
      label: "Total",
      render: (row) => (
        <>
          {" "}
          <div>
            <div
              className={`${row?.shipment_charges?.length > 1
                ? "shipments__total_updatedamount"
                : ""
                }`}
            >
              {row.Total ?? "-"}{" "}
              <sup>
                {row?.shipment_charges?.length > 1
                  ? `*${row?.shipment_charges?.length}`
                  : ""}
              </sup>
            </div>

            <div className="shipments__total_prevamount">
              {row?.shipment_charges?.length > 1
                ? row?.shipment_charges[row?.shipment_charges?.length - 2]
                  ?.total
                : ""}
            </div>
          </div>
        </>
      ),
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 22,
      label: "Created at",
      render: (row) => <>{row?.created_at?.slice(0, 10) ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 23,
      label: "",
      render: (row) => (
        <ActionDropDown
          row={row}
          handleTicketOpen={handleTicketOpen}
          setInvoiceModal={setInvoiceModal}
          printDeclaration={printDeclaration}
          labelDownloading={labelDownloading}
          CircularLoader={CircularLoader}
          printLabel={printLabel}
          handleReShip={handleReShip}
          setConfirmCancel={setConfirmCancel}
        />
      ),
      width: "350px",
      fixed: "right",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
  ];
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };
  const getAllShipments = async (page, perPage, filter) => {
    const response = await fetchShipments(tab, page, perPage, filter);
    if (!response.error) {
      setShipments(response?.data?.result?.shipments);
      dispatch(
        updateUser({
          unReadShipments: response?.data?.result?.unReadShipmentCount,
        })
      );
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  return (
    <div className="dispatcher__screen" style={{ maxWidth: "100%" }}>
      <Snackbar Snack={snack} SetSnack={setSnack} />
      <Confirm
        open={confirmCancel}
        setOpen={setConfirmCancel}
        handleConfirm={() => handleCancelShipment(confirmCancel)}
        text="Do you want to cancel this shipment?"
        loader={cancelingShipment}
      />
      <InvoiceModal
        open={!!invoiceModal}
        data={invoiceModal}
        setData={setInvoiceModal}
        setShipments={setShipments}
        setOpen={setInvoiceModal}
      />
      <TicketCreateModal
        open={!!ticketCreateModal}
        setOpen={setTicketCreateModal}
        previousData={ticketCreateModal}
        setSnack={setSnack}
      />
      <AssignPickup
        open={assignPickupModal}
        setOpen={setAssignPickupModal}
        setSnack={setSnack}
        setShipments={setShipments}
      />
      <TabsComponent items={tabPermissions} tab={tab} setTab={setTab} />
      <div className="orders_header">
        <div>
          <span className="orders_hdrheading">Shipments List</span>
        </div>
      </div>
      <div className="custom__table_size1 actionbtn__needtobe_fixwidth">
        <ShipmentsTable
          message={"No Shipments Available To Display!"}
          searchPlaceHolder={"Search Shipments"}
          getAllData={getAllShipments}
          data={shipments}
          columns={columns}
          refresh={tab}
        />
      </div>
    </div>
  );
};

export default Shipments;
