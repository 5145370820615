import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { addProductLot } from "../../../services/product.service";
const filter = createFilterOptions();

export default function LotDropdown({
  value,
  setSnack,
  open,
  rowId,
  setPallets,
  pallets,
  lots,
  setLots,
}) {
  const addNewLot = async (newOption) => {
    let payload = {
      name: newOption,
      shipper_id: open?.shipper?.id,
      product_id: open?.id,
      product_warehouse_id: open?.product_warehouse?.[0]?.id,
    };

    let response;
    response = await addProductLot(payload);
    if (!response.error) {
      setLots([...lots, response?.data?.result]);
      handleLotChange(response?.data?.result);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: newOption + " stored in lot/batch",
          severity: "success",
        };
      });
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: newOption + " not stored in lot/batch",
          severity: "error",
        };
      });
    }
  };

  const handleLotChange = (newValue) => {
    const updatedPallets = [...pallets];
    updatedPallets[rowId] = {
      ...updatedPallets[rowId],
      product_lot: newValue,
    };
    setPallets(updatedPallets);
  };

  return (
    <Autocomplete
      value={lots.find((option) => option.id === value?.id) || null}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          const selectedOption = lots.find(
            (option) => option.name === newValue
          );
          handleLotChange(selectedOption);
        } else if (newValue && newValue.inputValue) {
          addNewLot(newValue.inputValue);
        } else {
          handleLotChange(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const isExisting = options.some((option) => inputValue === option.name);
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            name: `Add "${inputValue}"`,
          });
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="product-location"
      options={lots}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.name;
      }}
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      freeSolo
      renderInput={(params) => <TextField {...params} size="small" />}
    />
  );
}
