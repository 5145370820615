import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Stepper from "./Components/stepper";
import Row1 from "./Components/Row1";
import AdditionalServices from "./Components/AdditionalServices";
import Row2 from "./Components/Row2";
import Row3 from "./Components/Row3";
import FinalStep from "./Components/FinalStep";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addUser, updateUser } from "../../../../../store/userSlice";
import { setdata } from "../../../../../helpers/helpers";
import InternationalForm from "./Components/InternationalForm";
import { showSnackBar } from "../../../../../store/common/snackBarSlice";
import {
  quoteService,
  specficQuoteService,
  createShipmentService,
} from "../../../../../services/ShipperServices/Shipping/shipping.services";

function getNextDay() {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const month = (tomorrow.getMonth() + 1).toString().padStart(2, "0");
  const day = tomorrow.getDate().toString().padStart(2, "0");
  return `${tomorrow.getFullYear()}-${month}-${day}`;
}

const initial_internationl = {
  product: {
    Description: "",
    Country: "",
    OriginCountryCode: "",
    InsuranceAmount: "",
    ReasonForExport: "",
    TotalPriceAmount: "",
    ExportReasonType: "",
  },
  producer: {
    CompanyName: "",
    Country: "",
    CountryCode: "",
  },
};

const initial_stepcheck = {
  ShipmentCheck: false,
  QuoteCheck: false,
  Pickup_Address_bit: 1,
  Delivery_Address_bit: 1,
  Additional_services_bit: 1,
  Packages_bit: 1,
  Quote_bit: 1,
  insurancetype: "Shipment",
  TotalWeight: 0,
  TotalVolume: 0,
  type: "Package",
  unit: "Imperial",
  fullform: false,
  ShipmentType: "",
  serviceType: "",
  Shipmentby: "",
  QuoteData: [],
  SingleQuoteData: "",
  FinalData: "",
  Pending: true,
  Final_Order: false,
  Disable: "",
  Products: "Products",
  Package: true,
  Pallet: false,
  International: initial_internationl,
  Warehouses: "",
  TotalFulfilmentCost: 0,
  TotalDeclaration: 0,
};

const initial_Flist = {
  address: "",
  country: "",
  countrycode: "",
  state: "",
  statecode: "",
  city: "",
  zip: "",
  company: "",
  id: "",
  attention: "",
  phone: "",
  otime: "",
  ctime: "",
  residential: false,
  date: getNextDay(),
  email: "",
  notification: false,
  instruction: "",
  ref: "",
  type: "Flist",
};

const initial_Tlist = {
  address: "",
  country: "",
  countrycode: "",
  state: "",
  statecode: "",
  city: "",
  zip: "",
  company: "",
  id: "",
  attention: "",
  phone: "",
  email: "",
  instruction: "",
  residential: false,
  notification: false,
  otime: "",
  ctime: "",
  date: "",
  ref: "",
  type: "Tlist",
};

const initial_Alist = {
  signature: false,
  residential: false,
  notification: false,
  returnlabel: false,
  insurance: false,
  insurancevalue: "",
  fragile: false,
  paperinvoice: false,
  dangerious: false,
  appointment: false,
  thirdpartybilling: false,
  cashondelivery: false,
  cashondeliveryvalue: "",
  ref: "",
  tailgatedelivery: false,
  tailgatepickup: false,
};

const initial_Aplist = {
  signature: false,
  residential: false,
  notification: false,
  returnlabel: false,
  insurance: false,
  insurancevalue: "",
  fragile: false,
  paperinvoice: false,
  dangerious: false,
  appointment: false,
  thirdpartybilling: false,
  cashondelivery: false,
  cashondeliveryvalue: "",
  ref: "",
};

const initial_Plist = [
  {
    name: "",
    length: "",
    width: "",
    height: "",
    weight: "",
    volume: "",
    insurance: "",
    description: "",
    feright: "",
    nmfc: "",
    type: "",
    pieces: "",
    declaration: "",
  },
];

const initial_stepper = {
  Pickup_Address: 1,
  Delivery_Address: 1,
  Packages: 1,
  Quote: 1,
  Additional_services: 0,
  ShipmentCheck: false,
};

function Ship() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [stepcheck, setstepcheck] = useState(initial_stepcheck);

  const [Flist, setFlist] = useState(initial_Flist);

  const [Tlist, setTlist] = useState(initial_Tlist);

  const [Alist, setAlist] = useState(initial_Alist);

  const [Aplist, setAplist] = useState(initial_Aplist);

  const [Plist, setPlist] = useState(initial_Plist);

  const [SelectedProducts, setSelectedProducts] = useState([]);

  const [stepper, setstepper] = useState(initial_stepper);

  useEffect(() => {
    if (localStorage.getItem("Reship")) {
      const Reship = JSON.parse(localStorage.getItem("Reship"));
      // console.log("Found");
      setFlist({
        address: setdata(() => Reship?.faddress, false, ""),
        country: setdata(() => Reship?.fcountry, false, ""),
        countrycode: setdata(() => Reship?.fcountrycode, false, ""),
        state: setdata(() => Reship?.fstate, false, ""),
        statecode: setdata(() => Reship?.fstatecode, false, ""),
        city: setdata(() => Reship?.fcity, false, ""),
        zip: setdata(() => Reship?.fzip, false, ""),
        company: setdata(() => Reship?.fcompany, false, ""),
        id: setdata(() => Reship?.fid, false, ""),
        attention: setdata(() => Reship?.fattension, false, ""),
        phone: setdata(() => Reship?.fphone, false, ""),
        otime: setdata(() => Reship?.readyopentime, false, ""),
        ctime: setdata(() => Reship?.readyclosetime, false, ""),
        residential: Reship?.fresidential,
        date: getNextDay(),
        email: setdata(() => Reship?.femail, false, ""),
        notification: Reship?.fnotification,
        instruction: setdata(() => Reship?.finstructions, false, ""),
        ref: "",
        type: "Flist",
      });
      setTlist({
        address: setdata(() => Reship?.taddress, false, ""),
        country: setdata(() => Reship?.tcountry, false, ""),
        countrycode: setdata(() => Reship?.tcountrycode, false, ""),
        state: setdata(() => Reship?.tstate, false, ""),
        statecode: setdata(() => Reship?.tstatecode, false, ""),
        city: setdata(() => Reship?.tcity, false, ""),
        zip: setdata(() => Reship?.tzip, false, ""),
        company: setdata(() => Reship?.tcompany, false, ""),
        id: setdata(() => Reship?.tid, false, ""),
        attention: setdata(() => Reship?.tattension, false, ""),
        phone: setdata(() => Reship?.tphone, false, ""),
        otime: setdata(() => Reship?.deliveryopentime, false, ""),
        ctime: setdata(() => Reship?.deliveryclosetime, false, ""),
        residential: Reship?.tresidential,
        date: "",
        email: setdata(() => Reship?.temail, false, ""),
        notification: Reship?.tnotification,
        instruction: setdata(() => Reship?.tinstructions, false, ""),
        ref: setdata(() => Reship?.referance_code, false, ""),
        type: "Tlist",
      });
      setAlist({
        signature: Reship?.signature,
        residential: false,
        notification: false,
        returnlabel: Reship?.return_label,
        insurance: Reship?.insurance,
        insurancevalue: setdata(() => Reship?.insurance_amount, false, ""),
        fragile: Reship?.fragile,
        paperinvoice: Reship?.paper_invoice,
        dangerious: Reship?.dangerous,
        appointment: Reship?.appointment,
        thirdpartybilling: false,
        cashondelivery: Reship?.cash_on_delivery,
        cashondeliveryvalue: setdata(
          () => Reship?.cash_on_delivery_value,
          false,
          ""
        ),
      });
      setstepcheck({
        ShipmentCheck: false,
        QuoteCheck: false,
        Pickup_Address_bit: 0,
        Delivery_Address_bit: 0,
        Additional_services_bit: 0,
        Packages_bit: 0,
        Quote_bit: 1,
        insurancetype: setdata(() => Reship?.insurance_type, false, "Shipment"),
        TotalWeight: 0,
        TotalVolume: 0,
        type: setdata(() => Reship?.package_type, false, "Package"),
        unit: setdata(() => Reship?.unit, false, "Imperial"),
        fullform: true,
        ShipmentType: "",
        Shipmentby: "",
        QuoteData: [],
        SingleQuoteData: "",
        FinalData: "",
        Pending: true,
        Final_Order: false,
        Disable: "",
        Products: "Packages",
        Package: true,
        Pallet: false,
        International:
          Reship?.international === null || Reship?.international.length === 0
            ? initial_internationl
            : Reship?.international,
        Warehouses: "",
        TotalFulfilmentCost: 0,
      });
      const newPlist = [];
      Reship?.shipment_details.forEach((item) => {
        const newItem = {
          name: "",
          length: setdata(() => item.length, false, ""),
          width: setdata(() => item.width, false, ""),
          height: setdata(() => item.height, false, ""),
          weight: setdata(() => item.weight, false, ""),
          volume: setdata(() => item.volume, false, ""),
          insurance: setdata(() => item.insurance, false, ""),
          description: setdata(() => item.description, false, ""),
          feright: setdata(() => item.feright, false, ""),
          nmfc: setdata(() => item.nmfc, false, ""),
          type: setdata(() => item.type, false, ""),
          pieces: setdata(() => item.pieces, false, ""),
        };

        newPlist.push(newItem);
      });

      setPlist(newPlist);
      localStorage.removeItem("Reship");
    } else {
      localStorage.removeItem("Reship");
      // console.log("notfound");
    }
  }, []);

  const Getquote = (event) => {
    event.preventDefault();

    setstepcheck((prevStepCheck) => ({
      ...prevStepCheck,
      Pending: true,
      QuoteCheck: true,
      QuoteData: [],
    }));

    const payload = {
      unit: stepcheck["unit"],
      fromzip: Flist["zip"],
      fromcountry: Flist["country"],
      fromstate: Flist["state"],
      fromcity: Flist["city"],
      fromcountrycode: Flist["countrycode"],
      fromstatecode: Flist["statecode"],
      fresidential: Flist["residential"],
      taddress: Tlist["address"],
      faddress: Flist["address"],
      tozip: Tlist["zip"],
      tocountry: Tlist["country"],
      tostate: Tlist["state"],
      tocity: Tlist["city"],
      tocountrycode: Tlist["countrycode"],
      tostatecode: Tlist["statecode"],
      tresidential: Tlist["residential"],
      dimensions: Plist,
      totalweight: stepcheck["TotalWeight"],
      signature: Alist["signature"],

      pickup_date: getNextDay(),

      insurance: Alist["insurance"],
      insurance_amount: Alist["insurancevalue"],
      insurance_type: stepcheck["insurancetype"],
      tailgate_pickup: Alist["tailgatepickup"],
      tailgate_delivery: Alist["tailgatedelivery"],
    };

    quoteService(stepcheck, setstepcheck, payload);
  };

  const GetSpecific = async (event) => {
    event.preventDefault();

    const requestData = {
      unit: stepcheck["unit"],
      totalweight: stepcheck["TotalWeight"],
      type: stepcheck["ShipmentType"],
      service_type: stepcheck["serviceType"],

      fromzip: Flist["zip"],
      fromcountry: Flist["country"],
      fromstate: Flist["state"],
      fromcity: Flist["city"],
      fromcountrycode: Flist["countrycode"],
      fromstatecode: Flist["statecode"],
      fresidential: Flist["residential"],
      fromnotification: Flist["notification"],
      faddress: Flist["address"],

      taddress: Tlist["address"],
      tocountry: Tlist["country"],
      tostate: Tlist["state"],
      tocity: Tlist["city"],
      tozip: Tlist["zip"],
      tocountrycode: Tlist["countrycode"],
      tostatecode: Tlist["statecode"],
      tresidential: Tlist["residential"],
      tonotification: Tlist["notification"],

      signature: Alist["signature"],
      dangerous: Alist["dangerious"],
      fragile: Alist["fragile"],
      return_label: Alist["returnlabel"],
      paper_invoice: Alist["paperinvoice"],
      appointment: Alist["appointment"],
      cash_on_delivery: Alist["cashondelivery"],
      cash_on_delivery_value: Alist["cashondeliveryvalue"],

      pickup_date: Flist["date"],
      delivery_date: Tlist["date"],

      insurance: Alist["insurance"],
      insurance_amount: Alist["insurancevalue"],
      insurance_type: stepcheck["insurancetype"],
      tailgate_pickup: Alist["tailgatepickup"],
      tailgate_delivery: Alist["tailgatedelivery"],

      dimensions: Plist,
    };
    setstepcheck((prevStepCheck) => ({
      ...prevStepCheck,
      Disable: "finalquote",
      QuoteCheck: true,
    }));

    try {
      const response = await specficQuoteService(
        stepcheck["Shipmentby"],
        requestData
      );
      if (!response?.error && response?.data?.success === true) {
        setstepcheck((prevStepCheck) => ({
          ...prevStepCheck,
          QuoteCheck: true,
          SingleQuoteData: response?.data?.result[0],
          serviceType: response?.data?.result[0]["service_type"],
          Disable: "",
          Final_Order: true,
        }));
      } else {
        setstepcheck((prevStepCheck) => ({
          ...prevStepCheck,
          Disable: "",
          QuoteCheck: true,
        }));
        dispatch(
          showSnackBar({
            text: response?.data?.message ?? "Something went wrong",
            severity: "error",
          })
        );
      }
    } catch (error) {
      setstepcheck((prevStepCheck) => ({
        ...prevStepCheck,
        Disable: "",
        QuoteCheck: true,
      }));
      dispatch(
        showSnackBar({
          text: error?.response?.data?.message ?? "Something went wrong",
          severity: "error",
        })
      );
    }
  };

  const CreateShipment = async (event) => {
    event.preventDefault();
    setstepcheck((prevStepCheck) => ({
      ...prevStepCheck,
      Disable: "final",
    }));

    let salePrice = 0;
    const itemsArray = SelectedProducts.map((product, index) => {
      // Extract relevant information from each product
      salePrice += parseFloat(product.sale_price ?? 0) * product.quantity;
      const item = {
        item_id: product.id,
        product_lot_id: product?.selectedLotId,
        active_price: product.sale_price,
        regular_price: product.regular_price,
        sale_price: product.sale_price,
        sku: product.code,
        fulfillment_cost: product.fulfillment_cost,
        product_name: product.full_name,
        item_quantity: product.quantity,
        length: product.length,
        width: product.width,
        height: product.height,
        weight: product.weight,
        product_length_type: "Imperial",
        bolt_package_detail: product.package_details,
        item_subtotal: product.sale_price,
        item_subto_tax: 0,
        item_total: product.sale_price,
        item_total_tax: 0,
        item_tax_status: "taxable",
        note: product.note ?? "",
      };

      return item;
    });

    const order_details = {
      warehouse_id: stepcheck["Warehouses"],
      currency: "CAD",
      discount_total: 0,
      discount_tax: 0,
      type: "Manual",
      order_status_id: "2",
      total: salePrice,
      total_tax: 0,
      status: "Confirmed",
      items: itemsArray,
      fulfillment_cost: stepcheck.TotalFulfilmentCost,
      formula: {
        TotalFulfilmentCost: stepcheck.TotalFulfilmentCost,
        TotalWeightCost: stepcheck.TotalWeightCost,
        TotalPreCost: stepcheck.TotalPreCost,
        TotalFulfilmentWeight: stepcheck.TotalFulfilmentWeight,
        TotalPreProduct: stepcheck.TotalPreProduct,
        UnitType: stepcheck.UnitType,
        Formula: stepcheck.Formula,
      },
      warehouse: {
        full_address: Flist["address"],
        country: Flist["countrycode"],
        country_name: Flist["country"],
        state_provience: Flist["statecode"],
        state_name: Flist["state"],
        city_town: Flist["city"],
        zip_postal: Flist["zip"],
        company: Flist["company"],
        attention: Flist["attention"],
        phone: Flist["phone"],
        email: Flist["email"],
      },
      billing: {
        first_name: Tlist["attention"],
        company: Tlist["company"],
        address_1: Tlist["address"],
        address_2: "",
        city: Tlist["city"],
        state: Tlist["statecode"],
        postcode: Tlist["zip"],
        country: Tlist["countrycode"],
        email: Tlist["email"],
        phone: Tlist["phone"],
      },
      shipping: {
        first_name: Tlist["attention"],
        company: Tlist["company"],
        address_1: Tlist["address"],
        address_2: "",
        city: Tlist["city"],
        state: Tlist["statecode"],
        postcode: Tlist["zip"],
        country: Tlist["countrycode"],
        phone: Tlist["phone"],
      },
    };

    const Data = {
      company_id: stepcheck["Shipmentby"],
      unit: stepcheck["unit"],
      package_type: stepcheck["type"],
      total_weight: stepcheck["TotalWeight"],
      volume: stepcheck["TotalVolume"],

      insurance: Alist["insurance"],
      insurance_amount: Alist["insurancevalue"],
      insurance_type: stepcheck["insurancetype"],
      tailgate_pickup: Alist["tailgatepickup"],
      tailgate_delivery: Alist["tailgatedelivery"],

      signature: Alist["signature"],
      return_label: Alist["returnlabel"],
      shipment_status: "Creation",
      dangerous: Alist["dangerious"],
      fragile: Alist["fragile"],
      paper_invoice: Alist["paperinvoice"],
      appointment: Alist["appointment"],
      cash_on_delivery: Alist["cashondelivery"],
      cash_on_delivery_value: Alist["cashondeliveryvalue"],

      fcountry: Flist["country"],
      fcountrycode: Flist["countrycode"],
      fcity: Flist["city"],
      fstate: Flist["state"],
      fstatecode: Flist["statecode"],
      fzip: Flist["zip"],
      fid: Flist["id"],
      fcompany: Flist["company"],
      fphone: Flist["phone"],
      fattension: Flist["attention"],
      femail: Flist["email"],
      fnotification: Flist["notification"],
      finstructions: Flist["instruction"],
      readyopentime: Flist["otime"],
      readyclosetime: Flist["ctime"],
      shipdate: Flist["date"],
      faddress: Flist["address"],
      fresidential: Flist["residential"],
      f_reference: Flist["ref"],

      tcountry: Tlist["country"],
      tcountrycode: Tlist["countrycode"],
      tcity: Tlist["city"],
      tstate: Tlist["state"],
      tstatecode: Tlist["statecode"],
      tzip: Tlist["zip"],
      tcompany: Tlist["company"],
      tid: Tlist["id"],
      tphone: Tlist["phone"],
      tattension: Tlist["attention"],
      temail: Tlist["email"],
      tnotification: Tlist["notification"],
      tinstructions: Tlist["instruction"],
      taddress: Tlist["address"],
      tresidential: Tlist["residential"],
      deliveryopentime: Tlist["otime"],
      deliveryclosetime: Tlist["ctime"],
      referance_code: Tlist["ref"],

      dimensions: Plist,
      shipment_details: JSON.stringify(Plist),

      estimated_delivery_date: stepcheck["SingleQuoteData"]["delivery_date"],
      delivery_date: stepcheck["SingleQuoteData"]["delivery_date"],
      shipping_company_id: stepcheck["SingleQuoteData"]["comp_id"],
      BasePrice: stepcheck["SingleQuoteData"]["base_charge"],
      SubTotal: stepcheck["SingleQuoteData"]["base_charge"],
      Total: stepcheck["SingleQuoteData"]["total"],
      service_charge: stepcheck?.SingleQuoteData?.service_charge ?? 0,
      pickup_zone: stepcheck["SingleQuoteData"]["pickupZone"],
      delivery_zone: stepcheck["SingleQuoteData"]["deliveryZone"],
      type: stepcheck["ShipmentType"],
      service_type: stepcheck["SingleQuoteData"]["service_type"],
      response: stepcheck["SingleQuoteData"],
      credit_card_payment_method_id: stepcheck["credit_card_payment_method_id"],

      international:
        Tlist.country &&
        Tlist.countrycode &&
        Flist.country &&
        Flist.countrycode &&
        !(
          ["Canada", "canada"].includes(Tlist.country) &&
          ["CA", "ca"].includes(Tlist.countrycode) &&
          ["Canada", "canada"].includes(Flist.country) &&
          ["CA", "ca"].includes(Flist.countrycode)
        )
          ? stepcheck["International"]
          : [],

      order: order_details,
      order_id: stepcheck["Order_id"] ?? "",
      fulfillment_cost: stepcheck.TotalFulfilmentCost,
      sale:
        SelectedProducts.length > 0 && stepcheck["Warehouses"] ? true : false,
    };
    const response = await createShipmentService(stepcheck["Shipmentby"], Data);
    if (!response?.error) {
      if (response?.data?.success === true) {
        setstepcheck((prevStepCheck) => ({
          ...prevStepCheck,
          Disable: "",
          ShipmentCheck: true,
          Final_Order: false,
          FinalData: response?.data?.result,
        }));

        // Updating Store and Local Storage
        let key = "balance";
        let value = response.data.result.userBalance;
        const updatedUser = {
          ...user,
          balance: value,
          unReadShipments: (user.unReadShipments ?? 0) + 1,
          unReadPickups: (user.unReadPickups ?? 0) + 1,
          unReadTrackings: (user.unReadTrackings ?? 0) + 1,
          unReadInvoices: (user.unReadInvoices ?? 0) + 1,
        };
        dispatch(addUser(updatedUser));

        localStorage.setItem("user", JSON.stringify(updatedUser));
        // Updating Store and Local Storage
        dispatch(
          showSnackBar({
            text: response?.data?.message,
            severity: "success",
          })
        );
      } else {
        dispatch(
          showSnackBar({
            text: response?.data?.message ?? "Something went wrong",
            severity: "error",
          })
        );
        setstepcheck((prevStepCheck) => ({
          ...prevStepCheck,
          Disable: "",
        }));
      }
    } else {
      dispatch(
        showSnackBar({
          text:
            response?.error?.response?.data?.message ?? "Something went wrong",
          severity: "error",
        })
      );
      setstepcheck((prevStepCheck) => ({
        ...prevStepCheck,
        Disable: "",
      }));
    }
  };

  useEffect(() => {
    // Address
    let Pickup_Address = 1;
    let Delivery_Address = 1;
    if (stepcheck["fullform"] === false) {
      // Pickup Address
      if (
        Flist["country"] !== "" &&
        Flist["city"] !== "" &&
        Flist["state"] !== "" &&
        Flist["zip"] !== ""
      ) {
        if (stepcheck["Pickup_Address_bit"] === 1) {
          Pickup_Address = 1;
        } else {
          Pickup_Address = 0;
        }
      } else {
        if (
          stepcheck["Pickup_Address_bit"] === 1 &&
          stepcheck["Delivery_Address_bit"] === 1 &&
          stepcheck["Packages_bit"] === 1
        ) {
          Pickup_Address = 0;
        } else {
          Pickup_Address = 2;
        }
      }
      // Pickup Address

      // Delivery Address
      if (
        Tlist["country"] !== "" &&
        Tlist["city"] !== "" &&
        Tlist["state"] !== "" &&
        Tlist["zip"] !== ""
      ) {
        if (stepcheck["Delivery_Address_bit"] === 1) {
          Delivery_Address = 0;
        } else {
          Delivery_Address = 1;
        }
      } else {
        if (
          stepcheck["Delivery_Address_bit"] === 1 &&
          stepcheck["Packages_bit"] === 1
        ) {
          Delivery_Address = 0;
        } else {
          Delivery_Address = 2;
        }
      }
      // Delivery Address
    } else {
      // Pickup Address
      if (
        Flist["address"] !== "" &&
        Flist["country"] !== "" &&
        Flist["city"] !== "" &&
        Flist["state"] !== "" &&
        Flist["zip"] !== "" &&
        Flist["company"] !== "" &&
        Flist["phone"] !== "" &&
        Flist["attension"] !== "" &&
        // Flist[0]["femail"] !== "" &&
        Flist["date"] !== ""
      ) {
        if (stepcheck["Pickup_Address_bit"] === 1) {
          Pickup_Address = 1;
        } else {
          Pickup_Address = 0;
        }
      } else {
        if (
          stepcheck["Pickup_Address_bit"] === 1 &&
          stepcheck["Delivery_Address_bit"] === 1 &&
          stepcheck["Packages_bit"] === 1
        ) {
          Pickup_Address = 0;
        } else {
          Pickup_Address = 2;
        }
      }
      // Pickup Address

      // Delivery Address
      if (
        Tlist["address"] !== "" &&
        Tlist["country"] !== "" &&
        Tlist["city"] !== "" &&
        Tlist["state"] !== "" &&
        Tlist["zip"] !== "" &&
        Tlist["company"] !== "" &&
        Tlist["phone"] !== "" &&
        Tlist["attension"] !== ""
        // Tlist[0]["temail"] !== "" &&
        // Tlist[0]["tdeliverdate"] !== ""
      ) {
        if (stepcheck["Delivery_Address_bit"] === 1) {
          Delivery_Address = 0;
        } else {
          Delivery_Address = 1;
        }
      } else {
        if (
          stepcheck["Delivery_Address_bit"] === 1 &&
          stepcheck["Packages_bit"] === 1
        ) {
          Delivery_Address = 0;
        } else {
          Delivery_Address = 2;
        }
      }
      // Delivery Address
    }
    // Address

    // Packages
    let Packages = 1;
    Plist.forEach((element) => {
      if (
        element.length !== "" &&
        element.height !== "" &&
        element.width !== "" &&
        element.weight !== ""
      ) {
        if (stepcheck["Packages_bit"] === 1) {
          Packages = 0;
        } else {
          Packages = 1;
        }
      } else {
        if (stepcheck["Packages_bit"] === 1) {
          Packages = 0;
        } else {
          Packages = 2;
        }
      }
      if (
        Alist["insurance"] === true &&
        stepcheck["insurancetype"] !== "Shipment"
      ) {
        if (element.insurance !== "") {
          if (stepcheck["Packages_bit"] === 1) {
            Packages = 0;
          } else {
            Packages = 1;
          }
        } else {
          if (stepcheck["Packages_bit"] === 1) {
            Packages = 0;
          } else {
            Packages = 2;
          }
        }
      }
    });
    // Packages

    //  Quote
    let Quote = 1;
    if (stepcheck["Shipmentby"] === "") {
      Quote = 0;
    } else {
      Quote = 1;
    }
    // Quote

    //  Additonal Services
    let Additional_services = 1;
    if (
      Alist["insurance"] === true &&
      stepcheck["insurancetype"] === "Shipment" &&
      Alist["insurancevalue"] === ""
    ) {
      Additional_services = 2;
    } else {
      if (
        stepcheck["Additional_services_bit"] === 1 &&
        stepcheck["Packages_bit"] === 1
      ) {
        Additional_services = 0;
      } else {
        Additional_services = 1;
      }
    }
    // Additonal Services

    setstepper({
      ...stepcheck,
      Pickup_Address: Pickup_Address,
      Delivery_Address: Delivery_Address,
      Packages: Packages,
      Quote: Quote,
      Additional_services: Additional_services,
      ShipmentCheck: stepcheck["ShipmentCheck"],
    });
  }, [Flist, Tlist, stepcheck, Alist]);

  const reset = () => {
    setstepcheck(initial_stepcheck);
    setFlist(initial_Flist);
    setTlist(initial_Tlist);
    setAlist(initial_Alist);
    setPlist(initial_Plist);
    setstepper(initial_stepper);
  };

  useEffect(() => {
    if (localStorage.getItem("OrderShipment")) {
      const OrderShipment = JSON.parse(localStorage.getItem("OrderShipment"));

      setFlist({
        address: setdata(
          () => OrderShipment?.order_pickup_details[0]?.full_address,
          false,
          ""
        ),
        country: setdata(
          () => OrderShipment?.order_pickup_details[0]?.country_name,
          false,
          ""
        ),
        countrycode: setdata(
          () => OrderShipment?.order_pickup_details[0]?.country,
          false,
          ""
        ),
        state: setdata(
          () => OrderShipment?.order_pickup_details[0]?.state_name,
          false,
          ""
        ),
        statecode: setdata(
          () => OrderShipment?.order_pickup_details[0]?.state_provience,
          false,
          ""
        ),
        city: setdata(
          () => OrderShipment?.order_pickup_details[0]?.city_town,
          false,
          ""
        ),
        zip: setdata(
          () => OrderShipment?.order_pickup_details[0]?.zip_postal,
          false,
          ""
        ),
        company: setdata(
          () => OrderShipment?.order_pickup_details[0]?.company,
          false,
          ""
        ),
        id: "",
        attention: setdata(
          () => OrderShipment?.order_pickup_details[0]?.attention,
          false,
          ""
        ),
        phone: setdata(
          () => OrderShipment?.order_pickup_details[0]?.phone,
          false,
          ""
        ),
        otime: "",
        ctime: "",
        residential: false,
        date: getNextDay(),
        email: setdata(
          () => OrderShipment?.order_pickup_details[0]?.email,
          false,
          ""
        ),
        notification: false,
        instruction: "",
        ref: "",
        type: "Flist",
      });
      setTlist({
        address:
          setdata(
            () => OrderShipment?.order_shipping_address_details?.address_1,
            false,
            ""
          ) +
          " " +
          setdata(
            () => OrderShipment?.order_shipping_address_details?.address_2,
            false,
            ""
          ),
        country: setdata(
          () => OrderShipment?.order_shipping_address_details?.country_name,
          false,
          ""
        ),
        countrycode: setdata(
          () => OrderShipment?.order_shipping_address_details?.country,
          false,
          ""
        ),
        state: setdata(
          () => OrderShipment?.order_shipping_address_details?.state_name,
          false,
          ""
        ),
        statecode: setdata(
          () => OrderShipment?.order_shipping_address_details?.state,
          false,
          ""
        ),
        city: setdata(
          () => OrderShipment?.order_shipping_address_details?.city,
          false,
          ""
        ),
        zip: setdata(
          () => OrderShipment?.order_shipping_address_details?.postcode,
          false,
          ""
        ),
        company:
          setdata(
            () => OrderShipment?.order_shipping_address_details?.first_name,
            false,
            ""
          ) +
          " " +
          setdata(
            () => OrderShipment?.order_shipping_address_details?.last_name,
            false,
            ""
          ),
        id: "",
        attention:
          setdata(
            () => OrderShipment?.order_shipping_address_details?.first_name,
            false,
            ""
          ) +
          " " +
          setdata(
            () => OrderShipment?.order_shipping_address_details?.last_name,
            false,
            ""
          ),
        phone: setdata(
          () => OrderShipment?.order_billing_address_details?.phone,
          false,
          ""
        ),
        otime: "",
        ctime: "",
        residential: false,
        date: "",
        email: setdata(
          () => OrderShipment?.order_billing_address_details?.email,
          false,
          ""
        ),
        notification: false,
        instruction: setdata(() => OrderShipment?.customer_note, false, ""),
        ref: setdata(() => OrderShipment?.order_id, false, ""),
        type: "Tlist",
      });
      setAlist({
        signature: false,
        residential: false,
        notification: false,
        returnlabel: false,
        insurance: false,
        insurancevalue: "",
        fragile: false,
        paperinvoice: false,
        dangerious: false,
        appointment: false,
        thirdpartybilling: false,
        cashondelivery: false,
        cashondeliveryvalue: "",
      });
      setstepcheck({
        ShipmentCheck: false,
        QuoteCheck: false,
        Pickup_Address_bit: 0,
        Delivery_Address_bit: 0,
        Additional_services_bit: 0,
        Packages_bit: 0,
        Quote_bit: 1,
        insurancetype: "Shipment",
        TotalWeight: 0,
        TotalVolume: 0,
        type: "Package",
        unit: "Imperial",
        fullform: true,
        ShipmentType: "",
        serviceType: setdata(
          () => OrderShipment?.selected_shipping_detail["Service-type"],
          false,
          ""
        ),
        Shipmentby: parseInt(
          setdata(
            () => OrderShipment?.selected_shipping_detail["Comp-id"],
            false,
            ""
          )
        ),
        QuoteData: [],
        SingleQuoteData: "",
        FinalData: "",
        Pending: true,
        Final_Order: false,
        Disable: "",
        Products: "Products",
        Package: true,
        Pallet: false,
        International: initial_internationl,
        Warehouses: OrderShipment?.warehouse_id ?? "",
        TotalFulfilmentCost: 0,
        Order_id: OrderShipment?.id,
      });
      const newPlist = [];
      const newSelected = [];
      if (OrderShipment?.order_details?.length > 0) {
        OrderShipment?.order_details.forEach((item) => {
          const quantity = parseInt(item.item_quantity, 10);

          for (let i = 0; i < quantity; i++) {
            const newItem = {
              name: item.product_name || "",
              description: item.product_name || "",
              type: item.product_length_type || "Imperial",
              pieces: "",
            };

            // Check if the item has bolt_package_detail

            if (item?.bolt_package_detail) {
              const boltPackageDetail = item.bolt_package_detail;

              // Loop through each key in bolt_package_detail
              for (const key in boltPackageDetail) {
                if (boltPackageDetail.hasOwnProperty(key)) {
                  const boltItem = boltPackageDetail[key];

                  const isMetric = item.product_length_type === "Metric";

                  const length = isMetric
                    ? convertToImperial(
                        parseFloat(boltItem.length || 0).toFixed(2),
                        "length"
                      )
                    : boltItem.length;
                  const width = isMetric
                    ? convertToImperial(
                        parseFloat(boltItem.width || 0).toFixed(2),
                        "width"
                      )
                    : boltItem.width;
                  const height = isMetric
                    ? convertToImperial(
                        parseFloat(boltItem.height || 0).toFixed(2),
                        "height"
                      )
                    : boltItem.height;
                  const weight = isMetric
                    ? convertToImperial(
                        parseFloat(boltItem.weight || 0).toFixed(2),
                        "weight"
                      )
                    : boltItem.weight;

                  const newPlistItem = {
                    ...newItem,
                    length,
                    width,
                    height,
                    weight,
                  };

                  newPlist.push(newPlistItem);
                }
              }
            } else {
              // If bolt_package_detail is not present, use default values
              const length = item.length || 0;
              const width = item.width || 0;
              const height = item.height || 0;
              const weight = item.weight || 0;

              const isMetric = item.product_length_type === "Metric";

              const newPlistItem = {
                ...newItem,
                length: isMetric
                  ? convertToImperial(parseFloat(length).toFixed(2), "length")
                  : parseFloat(length).toFixed(2),
                width: isMetric
                  ? convertToImperial(parseFloat(width).toFixed(2), "width")
                  : parseFloat(width).toFixed(2),
                height: isMetric
                  ? convertToImperial(parseFloat(height).toFixed(2), "height")
                  : parseFloat(height).toFixed(2),
                weight: isMetric
                  ? convertToImperial(parseFloat(weight).toFixed(2), "weight")
                  : parseFloat(weight).toFixed(2),
              };

              newPlist.push(newPlistItem);
            }
            if (i === 0) {
              item.products.quantity = quantity;
              newSelected.push(item.products);
            }
          }
        });
        setPlist(newPlist);
        setSelectedProducts(newSelected);
      } else {
        setPlist(initial_Plist);
      }
      function convertToImperial(metricValue, dataType) {
        let imperialValue;

        if (
          dataType === "length" ||
          dataType === "width" ||
          dataType === "height"
        ) {
          imperialValue = (parseFloat(metricValue) / 2.54).toFixed(2);
        } else if (dataType === "weight") {
          imperialValue = (parseFloat(metricValue) * 2.205).toFixed(2);
        } else {
          // Handle unknown data types, if necessary
          imperialValue = metricValue;
        }

        return imperialValue;
      }
      localStorage.removeItem("OrderShipment");
    } else {
      localStorage.removeItem("OrderShipment");
      // console.log("notfound");
    }
  }, []);

  // console.log(Flist);
  // console.log(Tlist);
  // console.log(Alist);
  // console.log(Plist);
  // console.log(stepcheck);
  // useEffect(() => {
  // console.log(SelectedProducts);
  // }, [SelectedProducts]);

  return (
    <>
      <Stepper stepcheck={stepper} />

      {stepcheck["ShipmentCheck"] === false && (
        <Form
          onSubmit={
            stepcheck["QuoteCheck"] === false
              ? Getquote
              : stepcheck["Final_Order"] === true
              ? CreateShipment
              : GetSpecific
          }
        >
          {/* Row1 */}
          <Row1
            Flist={Flist}
            SetFlist={setFlist}
            Tlist={Tlist}
            SetTlist={setTlist}
            StepCheck={stepcheck}
            SetStepCheck={setstepcheck}
          />
          {/* Row1 */}

          {/* Addition services  */}
          <AdditionalServices
            List={Alist}
            SetList={setAlist}
            StepCheck={stepcheck}
            SetStepCheck={setstepcheck}
            Tlist={Tlist}
            setTlist={setTlist}
            Plist={Aplist}
          />
          {/* Addition services  */}

          {/* Row2 */}
          <Row2
            Plist={Plist}
            isInternational={
              Tlist.country &&
              Tlist.countrycode &&
              Flist.country &&
              Flist.countrycode &&
              !(
                ["Canada", "canada"].includes(Tlist.country) &&
                ["CA", "ca"].includes(Tlist.countrycode) &&
                ["Canada", "canada"].includes(Flist.country) &&
                ["CA", "ca"].includes(Flist.countrycode)
              )
            }
            SetPlist={setPlist}
            Alist={Alist}
            SetAlist={setAlist}
            StepCheck={stepcheck}
            SetStepCheck={setstepcheck}
            Stepper={stepper}
            SelectedProducts={SelectedProducts}
            setSelectedProducts={setSelectedProducts}
          />
          {/* Row2 */}

          {/* Internationl Shipment Form */}
          <InternationalForm
            StepCheck={stepcheck}
            SetStepCheck={setstepcheck}
            Tlist={Tlist}
            Flist={Flist}
            Plist={Plist}
          />
          {/* Internationl Shipment Form */}

          {/* Row3 */}
          <Row3
            StepCheck={stepcheck}
            Flist={Flist}
            Tlist={Tlist}
            Plist={Plist}
            SetStepCheck={setstepcheck}
            CreateShipment={CreateShipment}
          />
          {/* Row3 */}
        </Form>
      )}

      <FinalStep
        StepCheck={stepcheck}
        reset={reset}
        SetStepCheck={setstepcheck}
      />
    </>
  );
}

export default Ship;
